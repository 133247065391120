<template>
  <v-container fluid>
    <filter-panel
      :users="users"
      :loading="loading"
      @clear-filters="clearFilters"
      @apply-filters="applyFilters"
    />
    <v-card class="mt-4">
      <v-data-table
        v-model="selectedItems"
        :headers="headers"
        :items="pendingDecisions"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        :search="search"
        show-select
        item-key="id"
        class="elevation-1"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon color="primary" @click="viewItem(item)">mdi-eye</v-icon>
        </template>
        <template v-slot:item.Status="{ item }">
          <v-chip :color="getStatusColor(item.Status)" text-color="white" small>
            {{ getStatusName(item.Status) }}
          </v-chip>
        </template>
        <template v-slot:item.DocState="{ item }">
          <v-chip
            :color="getStatusColor(item.docs_confirmation.Status)"
            text-color="white"
            small
          >
            {{ getStatusName(item.docs_confirmation.Status) }}
          </v-chip>
        </template>
        <template v-slot:item.id="{ item }">
          <v-icon color="primary" @click="openDialog(item)">mdi-pencil</v-icon>
        </template>
        <template v-slot:item.created_at="{ item }">
          {{ formatDate(item.created_at) }}
        </template>
        <template v-slot:item.updated_at="{ item }">
          {{ formatDate(item.updated_at) }}
        </template>
      </v-data-table>

      <view-approval-item-modal v-model="viewDialog" :item="selectedItem" />
    </v-card>

    <v-dialog v-model="answerDialog" max-width="550px">
      <v-card>
        <v-card-title class="headline primary white--text">
          Update Status
          <v-spacer></v-spacer>
          <v-btn icon dark @click="answerDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pt-4">
          <v-row>
            <v-col cols="12">
              <v-select
                v-model="record.answer"
                :items="answers"
                label="Answer"
                item-text="text"
                item-value="value"
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="record.remarks"
                label="Remarks"
                outlined
                dense
                rows="3"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="answerDialog = false">Cancel</v-btn>
          <v-btn color="primary" @click="updateRecord" :loading="updating">Update</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <snackbar ref="snackbar"></snackbar>
  </v-container>
</template>

<script>
import FilterPanel from "../_components/FilterPanel.vue";
import ViewApprovalItemModal from "../_components/ViewItem.vue";
import { debounce } from "lodash";
import moment from "moment";

export default {
  components: {
    FilterPanel,
    ViewApprovalItemModal,
  },
  data() {
    return {
      search: "",
      loading: false,
      updating: false,
      users: [],
      totalItems: 0,
      pendingDecisions: [],
      selectedItems: [],
      viewDialog: false,
      selectedItem: null,
      options: {
        itemsPerPage: 10,
        page: 1,
        sortBy: ["id"],
        sortDesc: [false],
      },
      headers: [
        { text: "#", value: "id" },
        { text: "Doc No.", value: "docs_confirmation.DraftEntry" },
        {
          text: "Doc Type",
          value: "docs_confirmation.objecttype.DocumentName",
        },
        { text: "Originator", value: "docs_confirmation.originator.name" },
        { text: "Approver", value: "user.name" },
        // { text: "Stage", value: "stage.Name", sortable: false },
        { text: "Approver Status", value: "Status", sortable: false },
        { text: "Approval Status", value: "DocState", sortable: false },
        { text: "Remarks", value: "Remarks", sortable: false },
        { text: "Draft Update Date", value: "updated_at" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      filters: {},
      answerDialog: false,
      record: {},
      answers: [
        { value: "W", text: "Pending" },
        { value: "Y", text: "Approved" },
        { value: "N", text: "Rejected" },
      ],
    };
  },
  methods: {
    debouncedSearch: debounce(function () {
      this.fetchData();
    }, 500),
    formatDate(date) {
      return moment(date).format("LLL");
    },
    getStatusName(status) {
      const statusMap = {
        Y: "Approved",
        N: "Rejected",
        W: "Pending",
      };
      return statusMap[status] || status;
    },
    getStatusColor(status) {
      const colorMap = {
        Y: "success",
        N: "error",
        W: "warning",
      };
      return colorMap[status] || "grey";
    },
    fetchData() {
      this.loading = true;

      // Create an object with all potential parameters
      const allParams = {
        ...this.options,
        ...this.filters,
        search: this.search,
        status: "W",
        itemsPerPage: this.options.itemsPerPage,
        page: this.options.page,
        sortBy: this.options.sortBy.join(","),
        sortDesc: this.options.sortDesc.join(","),
      };

      // Filter out null, undefined, and empty string values
      const filteredParams = Object.entries(allParams).reduce((acc, [key, value]) => {
        if (value !== null && value !== undefined && value !== "") {
          acc[key] = value;
        }
        return acc;
      }, {});
      const params = new URLSearchParams(filteredParams);

      this.$store
        .dispatch("get", `/ApprovalService_DecisionReport?${params.toString()}`)
        .then((res) => {
          this.pendingDecisions = res.ResponseData.data;
          this.totalItems = res.ResponseData.total;
          this.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "error");
          this.loading = false;
        });
    },
    clearFilters() {
      this.filters = {};
      this.fetchData();
    },
    applyFilters(newFilters) {
      this.filters = newFilters;
      this.options.page = 1;
      this.fetchData();
    },
    viewItem(item) {
      this.selectedItem = item;
      this.viewDialog = true;
    },
    openDialog(item) {
      this.record = { ...item, answer: item.Status };
      this.answerDialog = true;
    },
    updateRecord() {
      this.updating = true;
      // Implement the API call to update the record
      this.$store
        .dispatch("post", "/update-approval-status", this.record)
        .then(() => {
          this.$refs.snackbar.show("Record updated successfully", "success");
          this.answerDialog = false;
          this.fetchData(); // Refresh the data
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "error");
        })
        .finally(() => {
          this.updating = false;
        });
    },
    getUsers() {
      this.$store
        .dispatch("get", "/users")
        .then((res) => {
          this.users = res.ResponseData;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "error");
        });
    },
  },
  watch: {
    options: {
      handler() {
        this.fetchData();
      },
      deep: true,
    },
  },
  created() {
    this.fetchData();
    this.getUsers();
  },
};
</script>
